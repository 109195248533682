<!--
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-06 15:39:56
 * @LastEditTime: 2023-05-18 17:34:28
 * @Descripttion: 学生详情
-->
<style lang="scss" scoped>
.student-detail {
    @include innerPage(40px 32px 48px 15px);
    @include pageTitle(0);

    .detail-wrapper {
        width: calc(100% - 15px);
        height: calc(100% - 74px);
        margin-top: 36px;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 58px;

        .info {

            &--base,
            &--study {
                width: 100%;
                box-sizing: border-box;
                padding-left: 12px;
            }

            &--base {
                margin-bottom: 28px;

                .el-form {
                    width: 908px;
                    justify-content: space-between;
                    align-items: flex-start;
                }

                .el-form-item {
                    width: 444px;
                    margin-bottom: 20px;
                    align-items: flex-start;

                    p {
                        min-height: 46px;
                        height: auto;
                        margin-top: 12px;
                        line-height: 28px;
                        padding: 9px 28px;

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }

            &--study {
                margin-top: 34px;

                .search-form {
                    width: 66.35%;
                    @include flexBox;

                    .el-form {
                        flex-grow: 1;
                        min-width: 500px;

                        &-item {
                            flex-grow: 1;
                        }
                    }

                    ::v-deep .el-input__inner {
                        background: #f1f2f8;
                    }

                    .el-button {
                        flex-shrink: 0;
                    }
                }

                .statistical {
                    &-wrapper {
                        margin-top: 24px;
                        @include flexBox(flex-start, flex-start);

                        h5 {
                            line-height: 36px;
                            color: #282828;
                        }
                    }

                    &-left {
                        width: 440px;
                        flex-shrink: 0;
                        margin-right: 16px;

                        li {
                            height: 302px;
                            margin-top: 14px;
                            background: #f1f2f8;
                            border-radius: 10px;
                            box-sizing: border-box;
                            padding: 30px 30px 0;

                            &:first-child {
                                margin-top: 0;
                            }

                            .progress {
                                width: 160px;
                                height: 160px;
                                position: relative;
                                margin: 20px auto 0;

                                .center-text {
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    flex-direction: column;
                                    @include flexBox(center);

                                    .percentage {
                                        font-size: 30px;
                                        color: #f66478;
                                        line-height: 1em;

                                        span {
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }

                            &.performance .no-data--empty {
                                margin-top: 20px;

                                img {
                                    width: 160px;
                                }
                            }

                            .performance-echarts {
                                width: 100%;
                                height: calc(100% - 36px);
                            }
                        }
                    }

                    &-right {
                        flex-grow: 1;
                        height: 618px;
                        background: #fff;
                        border-radius: 10px;
                        box-sizing: border-box;
                        padding: 32px 36px 0;

                        .tabs {
                            width: calc(100% - 68px);
                            height: 68px;
                            margin-left: 68px;
                            border-bottom: 1px solid rgba($color: #646464, $alpha: 0.05);
                            position: relative;
                            margin-top: 6px;
                            @include flexBox;

                            &:after {
                                content: '';
                                width: 38px;
                                height: 5px;
                                border-radius: 2.5px;
                                background: #58419c;
                                position: absolute;
                                left: 12px;
                                bottom: -2px;
                                @include defaultAni;
                            }

                            &.exam::after {
                                transform: translateX(160px);
                            }

                            li {
                                cursor: pointer;
                                color: #373639;

                                &:first-child {
                                    padding-right: 52px;
                                }

                                &:last-child {
                                    padding-left: 52px;
                                    position: relative;

                                    &::after {
                                        content: '';
                                        height: 20px;
                                        border-left: 1px solid rgba($color: #7a7a7a, $alpha: 0.2);
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                }

                                &.current,
                                &:not(.current):hover {
                                    color: #6c4ecb;
                                }
                            }
                        }

                        .trend {
                            &-type {
                                height: 58px;
                                color: #292929;
                                @include flexBox(flex-end);

                                li {
                                    cursor: pointer;
                                    margin-left: 16px;
                                }

                                .current,
                                li:not(.current):hover {
                                    color: #6340c8;
                                }
                            }

                            &-echarts {
                                width: 100%;
                                height: calc(100% - 190px);
                                position: relative;

                                &--container {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.custom-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
}
</style>

<template>
    <section class="student-detail">
        <div class="page-title">
            <h3>基本信息</h3>
            <el-button type="custom_primary" size="small" @click="$router.go(-1)">
                返回
            </el-button>
        </div>
        <div class="detail-wrapper">
            <div class="info--base">
                <el-form class="readonly" label-width="82px" ref="baseInfo" inline :model="baseInfo">
                    <el-form-item label="学生姓名">
                        <p>{{ baseInfo.stuser_name }}</p>
                    </el-form-item>
                    <el-form-item label="性别">
                        <p>{{ baseInfo.stuser_sex == 10 ? "男" : "女" }}</p>
                    </el-form-item>
                    <el-form-item label="学号">
                        <p>{{ baseInfo.stuser_stuno }}</p>
                    </el-form-item>
                    <el-form-item label="省/市/区">
                        <p>{{ baseInfo.stuser_province_city_area }}</p>
                    </el-form-item>
                    <el-form-item label="班级">
                        <p>{{ baseInfo.class_name }}</p>
                    </el-form-item>
                    <el-form-item label="入学时间">
                        <p>{{ baseInfo.stuser_entertime }}</p>
                    </el-form-item>
                    <el-form-item label="状态">
                        <p>{{ baseInfo.stuser_readstatus == 10 ? "在读" : "休学" }}</p>
                    </el-form-item>
                </el-form>
            </div>
            <div class="page-title">
                <h3>学习信息</h3>
            </div>
            <div class="info--study">
                <div class="search-form">
                    <el-form inline ref="searchForm" :model="searchForm">
                        <el-form-item>
                            <el-select v-model="searchForm.grade_semester" placeholder="选择年级学期">
                                <el-option v-for="(item, index) in gradeSemester" :key="index" :label="item.grade_semester"
                                    :value="`${item.sccla_grade},${item.sccla_semester}`" />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchForm.sysub_id" placeholder="选择科目" clearable>
                                <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                                    :value="item.sysub_id" />
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-button type="custom_primary" size="medium" @click="searchData">搜索</el-button>
                </div>
                <div class="statistical-wrapper">
                    <ul class="statistical-left">
                        <li class="pass-rate">
                            <h5 class="bold">考试成绩合格率</h5>
                            <div class="progress">
                                <el-progress class="percentage-progress danger" :width="160" :stroke-width="20"
                                    type="circle" stroke-linecap="butt" :percentage="user_study.pass_rate || 0"
                                    :show-text="false" />
                                <div class="center-text">
                                    <p class="pf_bold percentage">{{ user_study.pass_rate || 0 }}<span class="pf">%</span>
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="performance" :class="{ 'no-data': $isEmpty(user_study) && !loading }">
                            <h5 class="bold">作业成绩情况</h5>
                            <div class="performance-echarts" ref="performance"></div>
                            <div class="no-data--empty" v-if="$isEmpty(user_study) && !loading">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无统计数据哦~</p>
                            </div>
                        </li>
                    </ul>
                    <div class="statistical-right">
                        <h5 class="bold">成绩趋势</h5>
                        <ul :class="searchForm.ach_type == 2 ? 'tabs exam' : 'tabs'">
                            <li :class="{ current: searchForm.ach_type == 1 }" @click="changeScore(1)">作业成绩</li>
                            <li :class="{ current: searchForm.ach_type == 2 }" @click="changeScore(2)">考试成绩</li>
                        </ul>
                        <ul class="trend-type pf">
                            <li :class="{ current: searchForm.ach_time == 7 }" @click="changeTrend(7)">本周成绩</li>
                            <li :class="{ current: searchForm.ach_time == 30 }" @click="changeTrend(30)">本月成绩</li>
                            <li :class="{ current: searchForm.ach_time == -1 }" @click="changeTrend(-1)">本学期成绩</li>
                        </ul>
                        <div class="trend-echarts" :class="{ 'no-data': $isEmpty(user_study) && !loading }">
                            <div class="custom-loading" v-if="loading">
                                <div class="point-item"></div>
                                <div class="point-item"></div>
                                <div class="point-item"></div>
                            </div>
                            <div class="trend-echarts--container" ref="trend"></div>
                            <div class="no-data--empty" v-if="$isEmpty(user_study) && !loading">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无统计数据哦~</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { studentsInfo, getUserStudy, $studentGrade } from "@api/user";
import { mapState } from "vuex"
export default {
    name: 'user_studentDetail',
    computed: {
        ...mapState('common', ['subjectList']),
    },
    data() {
        return {
            baseInfo: {},
            user_study: {},
            searchForm: {
                grade_semester: null,
                ach_type: 1,// 1:作业成绩 2:考试成绩
                ach_time: 7,// 7: 本周  30: 本月  -1: 本学期
            },
            gradeSemester: [],
            updatePerformance: true,
            loading: false,
        }
    },
    async created() {
        await this.studentGrade();
        this.getStudentInfo();
        this.getUserDetail();
    },
    methods: {
        /** 获取学生基本信息 */
        async getStudentInfo() {
            const { data } = await studentsInfo({ stuser_id: this.$route.params.id });
            this.baseInfo = data || {};
        },
        /** 获取学生信息 */
        async getUserDetail() {
            this.loading = true;
            let params = { ...this.searchForm };
            if (params.grade_semester) {
                params = {
                    ...this.searchForm,
                    stuser_id: this.$route.params.id,
                    grade: params.grade_semester.split(',')[0],
                    semester: params.grade_semester.split(',')[1],
                }
            }
            delete params.grade_semester;
            const { data } = await getUserStudy(params);
            this.loading = false;
            this.user_study = data || {};
            this.$nextTick(() => {
                if (!this.$isEmpty(data)) {
                    if (this.updatePerformance) this.performanceEcharts();
                    this.trendEcharts();
                    this.updatePerformance = false;
                }
            })
        },
        /** 学生经历年级学期 */
        async studentGrade() {
            let { data } = await $studentGrade(this.$route.params.id);
            this.gradeSemester = data || [];
            if (!this.$isEmpty(data)) this.searchForm.grade_semester = `${data[0].sccla_grade},${data[0].sccla_semester}`;
        },
        /** 筛选数据 */
        searchData() {
            this.updatePerformance = true;
            this.getUserDetail();
        },
        /** 作业成绩情况图表 */
        performanceEcharts() {
            let data = []
            this.user_study.sh_data.map(item => data.push({
                value: item.count,
                name: `${item.sthom_wor_grade} ${item.count}次`
            }))
            let echarts = this.$echarts.init(this.$refs.performance)
            let option = {
                color: ['#f66478', '#6c4ecb', '#fcb332', '#2ac293'],
                tooltip: {
                    show: false
                },
                legend: {
                    itemGap: 30,
                    icon: 'circle',
                    show: true,
                    bottom: 24,
                    itemWidth: 10,
                    itemHeight: 10,
                    textStyle: {
                        fontSize: 14,
                        color: '#333333',
                        rich: {
                            a: {
                                verticalAlign: 'middle',
                            },
                        },
                        lineHeight: 10,
                        padding: [0, 0, -4, 4],
                    },
                },
                series: [
                    {
                        type: 'pie',
                        radius: [60, 82],
                        top: -64,
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false
                        },
                        data: [...data]
                    }
                ]
            };
            echarts.setOption(option)
        },
        /** 成绩趋势图表 */
        trendEcharts() {
            let echarts = this.$echarts.init(this.$refs.trend);
            echarts.resize();
            echarts.clear();
            let xAxisData = this.user_study.chart_data.x_data || [];
            let seriesData = this.user_study.chart_data.y_data || [];
            let option = {
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#6340c8',
                    extraCssText: 'border-radius: 8px',
                    borderColor: '#6340c8',
                    padding: [5, 10],
                    formatter: this.searchForm.ach_time === 7 && '{c}分' || '{b}<br>{c}分',
                    textStyle: {
                        color: '#fff',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(152, 126, 232, 0.26)'
                        }
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData,
                    offset: 18,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    nameTextStyle: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    offset: 5,
                    max: 120,
                    min: 0,
                    interval: 10,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: ['rgba(233,233,244,0.5)'],
                            type: 'dashed'
                        }
                    },
                    nameTextStyle: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                grid: {
                    show: false,
                    top: '2%',
                },
                series: [
                    {
                        type: 'line',
                        data: seriesData,
                        symbol: 'circle',
                        showSymbol: false,
                        symbolSize: 10,
                        smooth: true,
                        itemStyle: {
                            color: '#987ee8',
                            borderWidth: 2,
                            borderColor: '#fff',
                            shadowColor: 'rgba(99, 64, 200, 0.53)',
                            shadowBlur: 20
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgba(152, 126, 232, 0.8)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(152, 126, 232, 0)'
                                }
                            ])
                        },
                    }
                ],
                dataZoom: [
                    {
                        type: 'slider',
                        start: 0,
                        end: this.searchForm.ach_time === 7 && 100 || 20,
                        minSpan: this.searchForm.ach_time === 7 && 100 || 20,
                        show: this.searchForm.ach_time != 7,
                        height: 20,
                        bottom: 4,
                    }, { type: 'slider', show: false }
                ],
            };
            echarts.setOption(option);
        },
        /** 切换趋势图数据 */
        changeTrend(val) {
            this.searchForm.ach_time = val;
            this.getUserDetail();
        },
        /** 切换趋势图类型 */
        changeScore(val) {
            this.searchForm.ach_type = val;
            this.searchForm.ach_time = 7;
            this.getUserDetail();
        },
    },
}
</script>